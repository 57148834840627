<template>
     <section class="contact-area pt-120 pb-80 fix">
         <div class="container">
             <div class="row">
                 <div class="col-xxl-5 col-xl-6 col-lg-6">
                     <div class="section-title-wrapper mb-15">
                         <h5 class="section-subtitle mb-20">contact with us</h5>
                         <h2 class="section-title">Speak with our
                             consultant</h2>
                     </div>
                     <div class="contact-info mr-50 mr-xs-0  mr-md-0">
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                                 <a href="#"><i class="fad fa-comments"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span>Call Anytime</span>
                                 <h5><a href="tell:926668880000">+1 (909) 5367938</a></h5>
                             </div>
                         </div>
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                                 <a href="#"><i class="fal fa-envelope"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span>send email</span>
                                 <h5><a href="mailto:sale@techwaynecompany.com">sale@techwaynecompany.com</a> </h5>
                             </div>
                         </div>
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                                 <a href="#"><i class="fal fa-map-marker-alt"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span>visit office</span>
                                 <h5><a target="_blank" href="https://www.google.com/maps/place/Tech+Wayne+Company+Inc./@34.0526754,-117.5374994,13z/data=!4m18!1m12!4m11!1m6!1m2!1s0x80c34befa1574b67:0xc91e9a0970ee8a67!2sTech+Wayne+Company+Inc.,+11061+Rose+Ave,+Fontana,+CA+92337,+United+States!2m2!1d-117.5024798!2d34.0526804!1m3!2m2!1d-117.4957856!2d34.0535288!3m4!1s0x80c34befa1574b67:0xc91e9a0970ee8a67!8m2!3d34.0526804!4d-117.5024798">11061 Rose Ave, Fontana, California, 92337</a></h5>
                             </div>
                         </div>
                     </div>
                 </div>
                 <div class="col-xxl-7 col-xl-6 col-lg-6">
                     <div class="contact-form">
                         <form action="mail.php" id="contact-form" method="POST">
                             <div class="row">
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="name" type="text" placeholder="Your Name">
                                 </div>
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="email" type="email" placeholder="Email Adress">
                                 </div>
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="phone" type="text" placeholder="Phone">
                                 </div>
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="subject" type="text" placeholder="Subject">
                                 </div>
                                 <div class="col-xxl-12 col-xl-12 col-lg-12 mb-20">
                                     <textarea placeholder="Write Massage" name="massage"></textarea>
                                 </div>
                                 <div class="col-xxl-12 col-xl-12 mb-20">
                                     <button type="submit" class="tp-btn">Send a message</button>
                                 </div>
                             </div>
                         </form>
                         <p class="ajax-response"></p>
                     </div>
                 </div>
             </div>
         </div>
    </section>
</template>

<script>
export default {
    name:'ContactArea',
}
</script>