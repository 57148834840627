<template>
    <Header/>
    <Breadcrumb title="Portfolio Details" subtitle="Portfolio Details" />
    <ProjectDetailsArea/>
    <PaginationArea/>
    <FeaturesArea/>
    <Footer/>
</template>

<script>
import Header from '../../components/Home/Header.vue';
import Breadcrumb from '../../components/common/Breadcrumb.vue';
import ProjectDetailsArea from '../../components/PortfolioDetails/ProjectDetailsArea.vue';
import PaginationArea from '../../components/PortfolioDetails/PaginationArea.vue';
import FeaturesArea from '../../components/PortfolioDetails/FeaturesArea.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
   name:'PortfolioDetails',
   components:{
       Header,
       Breadcrumb,
       ProjectDetailsArea,
       PaginationArea,
       FeaturesArea,
       Footer,
   }
}
</script>