<template>
    <div class="post-comments mb-95">
         <div class="post-comment-title mb-40">
               <h3>Comments</h3>
         </div>
         <div class="latest-comments">
               <ul>
                  <li>
                     <div class="comments-box">
                           <div class="comments-avatar">
                              <img src="../../assets/img/blog/comment/comments-1.png" alt="">
                           </div>
                           <div class="comments-text">
                              <div class="avatar-name">
                                 <h5>David Angel Makel</h5>
                                 <span class="post-meta">October 26, 2020</span>
                              </div>
                              <p>The bee's knees bite your arm off bits and bobs he nicked it gosh gutted mate blimey, old off his nut argy bargy vagabond buggered dropped.</p>
                              <a href="#" class="comment-reply"> Reply</a>
                           </div>
                     </div>
                  </li>
                  <li class="children">
                     <div class="comments-box">
                           <div class="comments-avatar">
                              <img src="../../assets/img/blog/comment/comments-rep-1.png" alt="">
                           </div>
                           <div class="comments-text">
                              <div class="avatar-name">
                                 <h5>Bailey Wonger</h5>
                                 <span class="post-meta">October 27, 2020</span>
                              </div>
                              <p>Do one say wind up buggered bobby bite your arm off gutted mate, David victoria sponge cup of char chap fanny around.</p>
                              <a href="#" class="comment-reply"> Reply</a>
                           </div>
                     </div>
                  </li>
                  <li class="children">
                     <div class="comments-box">
                           <div class="comments-avatar">
                              <img src="assets/img/blog/comment/comments-rep-2.png" alt="">
                           </div>
                           <div class="comments-text">
                              <div class="avatar-name">
                                 <h5>Hilary Ouse</h5>
                                 <span class="post-meta">October 28, 2020</span>
                              </div>
                              <p>Baking cakes is cobblers wellies William geeza bits and bobs what a plonker it's your round,</p>
                              <a href="#" class="comment-reply">Reply</a>
                           </div>
                     </div>
                  </li>
               </ul>
         </div>
   </div>
</template>

<script>
export default {
    name:'PostComments'
}
</script>