import axios from "axios";

const API_BASE_URL = "https://determined-wealth-f3670dd3b7.strapiapp.com/api"; // Replace with your API base URL

// Set the static token as a default header
axios.defaults.headers.common["Authorization"] =
  "Bearer d2cd579dfb7c08091064d80751c8d238e7241d47566042b9c8cce6b06b9e9931883b0ae11701c230314dc694eabc3faacaae61462c65585456099e27e9aaa62091943bf78212145ac33d21ee263cc9e6772a655e2dd9233c365bb4c3150a747b5a35ac9c32bc8401676de9e72b108981db38a4cf56217cddf869e18dbbac4685"; // Replace with your actual static token

export async function homePage() {
  try {
    const response = await axios.get(`${API_BASE_URL}/home-pages/?populate=*`);
    return response.data;
  } catch (error) {
    throw new Error("Error fetching sliders:", error);
  }
}
export async function missionStatement() {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/mission-statment/?populate=*`
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching sliders:", error);
  }
}

// You can define more functions for other API requests here
