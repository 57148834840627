<template>
  <div
    class="main-services grey-bg pt-120 pb-90"
    :style="{
      backgroundImage:
        'url(' + require('../../assets/img/pattern/pt1.png') + ')',
    }"
  >
    <div class="container">
      <div class="row mb-60">
        <div class="col-12">
          <div class="sec-wrapper text-center">
            <h5>Services</h5>
            <h2 class="section-title">Explore Our Services.</h2>
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div
          v-for="service in servicesData"
          :key="service.id"
          class="col-xl-4 col-lg-4 col-md-6 mb-30"
        >
          <div class="mfbox mfbox-white">
            <div class="mf-shape"></div>
            <div class="mfbox__icon mb-15">
              <i :class="service.icon"></i>
            </div>
            <div class="mfbox__text">
              <h3 class="mf-title" v-html="service.title"></h3>
              <p>
                Lorem Ipsum is simply dummy text of free available in market the
                printing and typesetting industry.
              </p>
            </div>
            <div class="mf-btn">
              <router-link class="squire-btn" to="/services-details">
                <i class="fal fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesArea",
  data() {
    return {
      servicesData: [
        {
          id: "1",
          icon: "flaticon-insight",
          title: "Fully Responsive for <br> all devices",
        },
        {
          id: "2",
          icon: "flaticon-remarketing",
          title: "Largest Business <br> experts",
        },
        {
          id: "3",
          icon: "flaticon-technical-support",
          title: "SEO & Content <br> writting",
        },
      ],
    };
  },
};
</script>
