<template>
       <section class="team__details pt-120 pb-160">
         <div class="container">
            <div class="team__details-inner p-relative white-bg">
               <div class="row">
                  <div class="col-xl-6 col-lg-6">
                     <div class="team__details-img w-img mr-70">
                        <img src="../../assets/img/team/details/team-01.jpg" alt="">
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-6">
                     <div class="team__details-content pt-105">
                        <span>UI/UX Designer</span>
                        <h3>Parsley Montana</h3>
                        <p>So I said on your bike mate easy peasy dropped a
                           clanger blow porkies is fantastic show off show.!</p>
                        <div class="team__details-contact mb-45">
                           <ul>
                              <li >
                                 <div class="icon theme-color ">
                                    <i class="fal fa-envelope"></i>
                                 </div>
                                 <div class="text theme-color ">
                                    <span><a href="mailto:support@zibber.com">support@zibber.com</a></span>
                                 </div>
                              </li>
                              <li >
                                 <div class="icon theme-color">
                                    <i class="fas fa-phone-volume"></i>
                                 </div>
                                 <div class="text theme-color">
                                    <span><a href="tel:(+642)-394-396-432">(+642) 394 396 432</a></span>
                                 </div>
                              </li>
                              <li >
                                 <div class="icon">
                                    <i class="fal fa-map-marker-alt"></i>
                                 </div>
                                 <div class="text">
                                    <a target="_blank"
                                       href="https://www.google.com/maps/place/Dhaka/@23.7806207,90.3492859,12z/data=!3m1!4b1!4m5!3m4!1s0x3755b8b087026b81:0x8fa563bbdd5904c2!8m2!3d23.8104753!4d90.4119873">Ave
                                       14th Street, Mirpur 210, <br> San Franciso, USA 3296.</a>
                                 </div>
                              </li>
                           </ul>
                        </div>
                        <div class="team__details-social theme-social wow fadeInUp" >
                           <ul>
                              <li>
                                 <a href="#">
                                    <i class="fab fa-facebook-f"></i>
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <i class="fab fa-twitter"></i>
                                 </a>
                              </li>
                              <li>
                                 <a href="#">
                                    <i class="fab fa-vimeo-v"></i>
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-xl-10 offset-xl-1">
                  <div class="team__details-info mt-60">
                     <h4 >Information</h4>
                     <p >jolly good codswallop what a plonker he nicked it
                        bog-standard porkies gosh the full monty, wind up at public school hanky panky cheeky bugger
                        Richard do one some dodgy chav bite your arm off. Argy-bargy excuse my French brown bread up the
                        duff bleeder fanny around spend a penny barmy bonnet, bubble and squeak brolly bugger no biggie
                        smashing get stuffed mate old lurgy, cup of tea nice one mufty that I knackered some dodgy chav.
                        Say vagabond morish crikey excuse my French bonnet William blatant spend a penny, knackered bite
                        your arm off what a plonker blimey smashing a blinding shot pardon me grub, wind up cracking
                        goal Jeffrey hanky panky are you taking the piss such a fibber hunky-dory.</p>
                     <p >So I said on your bike mate easy peasy dropped a
                        clanger blow off porkies is fantastic show off show off pick your nose and blow off, faff about
                        bubble and squeak bugger all mate happy days hotpot don't get shirty with me jolly good gormless
                        barmy.</p>

                     <router-link to="/contact" class="tp-btn mt-10" >
                     Appionment</router-link>
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>

<script>
export default {
   name:'TeamDetailsArea'
}
</script>