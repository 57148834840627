<template>
    <div class="about-area pt-120 pb-90">
            <div class="container">
               <div class="row">
                  <div class="col-xl-6 col-lg-6">
                     <div class="ab-wrapper pos-rel">
                        <div class="ab-since">
                           <h4>Since <br> 2015</h4>
                        </div>
                        <div class="row gx-2 mb-30">
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/about/flue-keepers-3.jpg" alt="">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/about/scissors-lift-rental-2.jpg" alt="">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/about/relocation-2.jpg" alt="">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/about/high-pile-permit-2.jpg" alt="">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-6">
                     <div class="ab-box pl-50 mb-30">
                        <div class="sec-wrapper">
                           <h5>About Us</h5>
                           <h2 class="section-title">We are trusted by more than <span>6,800 clients.</span></h2>
                        </div>
                        <div class="ab-item-box mt-40 mb-30">
                           <div class="ab-items fix mb-20">
                              <div class="ab-icon f-left">
                                 <i class="flaticon-honesty"></i>
                              </div>
                              <div class="ab-item-text fix">
                                 <h3 class="ab-item-title">Pleasant Consulting</h3>
                                 <p>Lorem Ipsum nibh vel velit auctor aliquet. Aenean sollic tudin, lorem is simply free text quis bibendum.</p>
                              </div>
                           </div>
                           <div class="ab-items fix mb-20">
                              <div class="ab-icon f-left">
                                 <i class="flaticon-trustworthy"></i>
                              </div>
                              <div class="ab-item-text fix">
                                 <h3 class="ab-item-title">Trusted Services</h3>
                                 <p>Lorem Ipsum nibh vel velit auctor aliquet. Aenean sollic tudin, lorem is simply free text quis bibendum.</p>
                              </div>
                           </div>
                        </div>
                        <div class="ab-btn">
                           <router-link to="/about" class="tp-btn">Learn More</router-link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
</template>

<script>
export default {
    name:'AboutUs'
}
</script>