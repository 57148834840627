<template>
    <Header/>
    <HomeTwoSlider/>
    <HomeTwoServices/>
    <HomeTwoAbout/>
    <HomeTwoFaq/>
    <Team/>
    <Testimonials/>
    <HomeTwoPortfolio/>
    <Cta/>
    <HomeTwoBlog/>
    <HomeTwoFooter/>
</template>

<script>
import Header from '../../components/Home-two/HomeTwoHeader.vue';
import HomeTwoSlider from '../../components/Home-two/HomeTwoSlider.vue';
import HomeTwoServices from '../../components/Home-two/HomeTwoServices.vue';
import HomeTwoAbout from '../../components/Home-two/HomeTwoAbout.vue';
import HomeTwoFaq from '../../components/Home-two/HomeTwoFaq.vue';
import Team from '../../components/Home/Team.vue';
import Testimonials from '../../components/Home-two/Testimonials.vue';
import HomeTwoPortfolio from '../../components/Home-two/HomeTwoPortfolio.vue';
import Cta from '../../components/Home/Cta.vue';
import HomeTwoBlog from '../../components/Home-two/HomeTwoBlog.vue';
import HomeTwoFooter from '../../components/Home-two/HomeTwoFooter.vue';

export default {
    name:'HomeTwo',
    components:{
        Header,
        HomeTwoSlider,
        HomeTwoServices,
        HomeTwoAbout,
        HomeTwoFaq,
        Team,
        Testimonials,
        HomeTwoPortfolio,
        Cta,
        HomeTwoBlog,
        HomeTwoFooter,
    }
}
</script>