<template>
      <section class="portfolio-area pt-120 pb-70">
         <div class="container">
            <div class="row mb-40 d-none">
               <div class="col-12">
                  <div class="sec-wrapper text-center">
                     <h5>Features Project</h5>
                     <h2 class="section-title">Explore Our Project.</h2>
                  </div>
               </div>
            </div>
             <div class="row align-items-center mb-50">
                 <!-- START PORTFOLIO FILTER AREA -->
                 <div class="col-xl-6 col-lg-6">
                  <div class="sec-wrapper text-centers">
                     <h5>Features Project</h5>
                     <h2 class="section-title">Explore Our Project.</h2>
                  </div>
                 </div>
                 <div class="col-xl-6 col-lg-6">
                     <div class="text-lg-end">
                         <div class="portfolio-filter">
                              <nav>
                                    <div className="nav nav-tabs justify-content-center" id="portfolio-tab" role="tablist">
                                    <button className="nav-link active" id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all" aria-selected="true">SHOW ALL</button>

                                    <button className="nav-link" id="nav-design-tab" data-bs-toggle="tab" data-bs-target="#nav-design" type="button" role="tab" aria-controls="nav-design" aria-selected="false">DESIGN</button>

                                    <button className="nav-link" id="nav-logo-tab" data-bs-toggle="tab" data-bs-target="#nav-logo" type="button" role="tab" aria-controls="nav-logo" aria-selected="false">LOGO</button>

                                    <button className="nav-link" id="nav-business-tab" data-bs-toggle="tab" data-bs-target="#nav-business" type="button" role="tab" aria-controls="nav-business" aria-selected="false">BUSINESS</button>

                                    <button className="nav-link" id="nav-agency-tab" data-bs-toggle="tab" data-bs-target="#nav-agency" type="button" role="tab" aria-controls="nav-agency" aria-selected="false">AGENCY</button>
                                    </div>
                             </nav>
                         </div>
                     </div>
                 </div>
                 <!-- END PORTFOLIO FILTER AREA -->
             </div>
             <div id="portfolio-grid" class="row row-portfolio tab-content">

                 <div className="tab-pane fade show active" id="nav-all" role="tabpanel"   aria-labelledby="nav-all-tab">
                   <PortfolioAllItems/>
                </div>

                   <div className="tab-pane fade" id="nav-design" role="tabpanel" aria-labelledby="nav-design">
                       <PortfolioOne/>
                   </div>

                   <div className="tab-pane fade" id="nav-logo" role="tabpanel" aria-labelledby="nav-logo">
                       <PortfolioTwo/>
                   </div>

                   <div className="tab-pane fade" id="nav-business" role="tabpanel" aria-labelledby="nav-business">
                      <PortfolioThree/>
                   </div>

                   <div className="tab-pane fade" id="nav-agency" role="tabpanel" aria-labelledby="nav-agency">
                     <PortfolioFour/>
                  </div>
             </div>
         </div>
       </section>
</template>

<script>
import PortfolioAllItems from './PortfolioFilterItems/PortfolioAllItems.vue';
import PortfolioOne from './PortfolioFilterItems/PortfolioOne.vue';
import PortfolioTwo from './PortfolioFilterItems/PortfolioTwo.vue';
import PortfolioThree from './PortfolioFilterItems/PortfolioThree.vue';
import PortfolioFour from './PortfolioFilterItems/PortfolioFour.vue';

export default {
    name:'HomeTwoPortfolio',
      components: {
         PortfolioAllItems,
         PortfolioOne,
         PortfolioTwo,
         PortfolioThree,
         PortfolioFour,
      }
}
</script>