<template>
    <Header/>
    <Breadcrumb title="Services" subtitle="Services" />
    <ServicesArea/>
    <About/>
    <Services/>
    <!-- <BrandArea border="brand-border" /> -->
    <QualityArea/>
    <Footer/>
</template>

<script>
import Header from '../../components/Home/Header.vue';
import Breadcrumb from '../../components/common/Breadcrumb.vue';
import ServicesArea from '../../components/Services/ServicesArea.vue';
import About from '../../components/Home-two/HomeTwoAbout.vue';
import Services from '../../components/Home/Services.vue';
// import BrandArea from '../../components/common/BrandArea.vue';
import QualityArea from '../../components/common/QualityArea.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
    name:'ServicesPage',
    components:{
        Header,
        Breadcrumb,
        ServicesArea,
        About,
        Services,
        // BrandArea,
        QualityArea,
        Footer,
    }
}
</script>