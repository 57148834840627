<template>
       <div class="portfolio__pagination-wrapper">
               <div class="container">
                  <div class="pagination-border pt-40 pb-40">
                     <div class="row">
                           <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                              <div class="portfolio__pagination">
                                 <router-link to="/portfolio-details" class="link-btn-2">
                                       <i class="fal fa-long-arrow-left"></i>
                                       Next
                                 </router-link>
                              </div>
                           </div>
                              
                           <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                              <div class="portfolio__pagination text-end">
                                 <router-link to="/portfolio-details" class="link-btn-2">
                                       Prev <i class="fal fa-long-arrow-right"></i>
                                 </router-link>
                              </div>
                           </div>
                     </div>
                  </div>
               </div>
         </div>
</template>

<script>
export default {
    name:'PaginationArea'
}
</script>