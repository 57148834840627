<template>
  <div class="about-area pt-120 pb-90">
    <div class="container">
      <div class="row">
        <div class="col-xl-5 col-lg-6">
          <div class="ab-box pl-50 mb-30">
            <div class="sec-wrapper">
              <h5>About Us</h5>
              <h2 class="section-title">We Are Here To Help You</h2>
              <p>
                Tech Wayne Company installs and sells full-line of New and Used
                We Are Here To Help You Tech Wayne Company installs and sells
                full-line of New and Used Pallet Racks, include Drive in, Push
                backs, Cantilever Racks, Rack supported mezzanines, shelving,
                Spank tracks, and ETC We provide services for all warehouse
                needs to increase efficiency include onsite free consultation We
                provide evaluation of relocation services for moving of
                warehouses We provide permit service, and anchor inspections We
                provide zoned damaged repairing rack services We bring a Full
                Crew of experience consultants and installers to help complete
                the job properly and quickly. We service all of area or counties
                in Southern California.
              </p>
            </div>
            <div class="abs-item-box mt-40 mb-30">
              <div class="row">
                <div class="col-6">
                  <div class="abs-items mb-20">
                    <div class="abs-icon mb-15">
                      <i class="icon_ribbon_alt"></i>
                    </div>
                    <div class="abs-item-text fix">
                      <h3 class="abs-item-title">
                        Acquisitions Finance Consulting
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="abs-items mb-20">
                    <div class="abs-icon mb-15">
                      <i class="icon_lightbulb_alt"></i>
                    </div>
                    <div class="abs-item-text fix">
                      <h3 class="abs-item-title">
                        Private Placement Consulting
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ab-btn">
              <router-link to="/about" class="tp-btn">Learn More</router-link>
            </div>
          </div>
        </div>
        <div class="col-xl-7 col-lg-6">
          <div class="abs-images abs-images-2 pl-50">
            <div class="row">
              <div class="col-7">
                <div class="abs-img img-filter mb-30">
                  <img src="../../assets/img/about/high-pile-permit-2.jpg" alt="" />
                </div>
              </div>
              <div class="col-5">
                <div class="abs-img img-filter mb-30">
                  <img src="../../assets/img/about/relocation-2.jpg" alt="" />
                </div>
                <div class="ab-line-shape w-100 mb-20"></div>
                <div class="ab-line-shape w-50"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeTwoAbout",
};
</script>
