<template>
     <div class="contact-map">
        <div id="contact-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52890.507747152056!2d-117.53749935617166!3d34.052675434919045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c34befa1574b67%3A0xc91e9a0970ee8a67!2sTech%20Wayne%20Company%20Inc.!5e0!3m2!1sen!2s!4v1664664715797!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name:'CotactMap'
}
</script>